import React from 'react'
import 'react-alice-carousel/lib/alice-carousel.css'

// graphQL
import { getGraphComics, getGraphGenres } from '../../../graphQL'

// Utils
import { shuffleArray } from '../../../utils/utils'

// Model
import { ComicModel, GenreModel } from '../../../models'

// Components
import Carrousel from '../../organisms/Carrousel'

// Style
import { homeSeeAll } from './style.module.scss'

type GruopedComics = {
  genre: GenreModel
  comics: ComicModel[]
}

const HomeTemplate = () => {
  const allComics = getGraphComics()
  const genres = getGraphGenres()
  console.log(genres.length)

  const groupedComics: GruopedComics[] = []
  genres.forEach((genre) => {
    const comics = allComics.filter(
      (comic) =>
        comic.genres.some((g) => genre.id === String(g.id)) && comic.cover
    )
    groupedComics.push({ genre, comics: shuffleArray(comics) })
  })

  groupedComics.sort((a, b) => b.comics.length - a.comics.length)

  return (
    <>
      {groupedComics.map(
        (group) =>
          group.comics.length > 4 && (
            <div key={`home_${group.genre.name}_carrousel`}>
              <h3>
                {group.genre.name} ({group.comics.length})
                <span className={homeSeeAll} onClick={() => {}}>
                  Ver todos
                </span>
              </h3>
              <Carrousel comics={group.comics} genre={group.genre.name} />
            </div>
          )
      )}
    </>
  )
}

export default HomeTemplate
