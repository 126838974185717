import { HeadFC, PageProps } from 'gatsby'
import React, { BaseSyntheticEvent } from 'react'

// Models
import { AuthorModel, ComicModel } from '../../../models'

// Icons
import NoCover from '../../../assets/icons/NoCover.png'
import { Email, Facebook, Instagram, Twitter } from '../../../assets/icons/'

// Components
import PageTemplate from '../PageTemplate'
import ComicCard from '../../atoms/ComicCard'
import DefaultHelmet from '../../atoms/DefaultHelmet'

// graphQL
import { getGraphComics } from '../../../graphQL'

// Styles
import {
  authorTop,
  authorJob,
  authorLink,
  authorInfo,
  authorTech,
  authorTechTitle,
  authorCover,
  authorTitle,
  authorComics,
  authorDetail,
  authorSubtitle,
  authorCoverWrapper,
} from './style.module.scss'

const AuthorTemplate: React.FC<PageProps<any, AuthorModel>> = ({
  pageContext: {
    job,
    email,
    image,
    comics,
    twitter,
    facebook,
    fullname,
    instagram,
    pseudonym,
    description,
  },
}) => {
  const allComics = getGraphComics()
  return (
    <PageTemplate>
      <h1 className={authorTitle}>
        {pseudonym ? (
          <>
            {pseudonym}
            {fullname && <span className={authorSubtitle}>({fullname})</span>}
          </>
        ) : (
          fullname
        )}
      </h1>
      {job && <h3 className={authorJob}>{job}</h3>}
      <div className={authorTop}>
        <div className={authorCoverWrapper}>
          <img
            className={authorCover}
            src={`https://comiccolombiano.com/.img/authors/${image}`}
            onError={(e: BaseSyntheticEvent) => {
              e.target.src = NoCover
            }}
            alt={`Foto de ${pseudonym || fullname}`}
          />
        </div>
        <div className={authorInfo}>
          {description && (
            <div className={authorDetail}>
              {description || 'Descripción pendiente'}
            </div>
          )}
          {(facebook || email || instagram || twitter) && (
            <div className={authorTech}>
              <h3 className={[authorTitle, authorTechTitle].join(' ')}>
                Información de contacto
              </h3>
              {instagram && (
                <a
                  href={`https://www.instagram.com/${instagram}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className={authorLink}>
                    <Instagram />
                    <div>{instagram}</div>
                  </div>
                </a>
              )}
              {facebook && (
                <a
                  href={`https://www.facebook.com/${facebook}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className={authorLink}>
                    <Facebook />
                    <div>{facebook}</div>
                  </div>
                </a>
              )}
              {twitter && (
                <a
                  href={`https://www.twitter.com/${twitter}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className={authorLink}>
                    <Twitter />
                    <div>{twitter}</div>
                  </div>
                </a>
              )}
              {email && (
                <div className={authorLink}>
                  <Email />
                  <div>{email}</div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <h2 className={authorTitle}>Comics</h2>
      <div className={authorComics}>
        {comics?.map((comic) => (
          <div>
            <ComicCard
              key={`author_comic_${comic.id}`}
              comic={
                allComics.find((c) => c.id === String(comic.id)) as ComicModel
              }
              role={comic.role ?? undefined}
            />
          </div>
        ))}
      </div>
    </PageTemplate>
  )
}

export const Head: HeadFC<any, AuthorModel> = ({
  pageContext: { role, fullname, pseudonym, description },
}) => (
  <>
    <title>{pseudonym || fullname} - Comic Colombiano</title>
    <meta
      name="description"
      content={
        description ??
        '¡Novelas gráficas, revistillas, webcómics y más! Conoce aquí el catálogo más grande de cómic colombiano y sus autores. ¡La inscripción es gratuita!'
      }
    />
    <meta
      name="keywords"
      content={[
        [fullname, pseudonym, role].join(' ').split(' ').join(','),
        'colombiano,autor,base,de,datos,ilustrador,escritor,diseñador,novela,grafica,arte,revistillas,catalogo',
      ].join(',')}
    />
    <DefaultHelmet />
  </>
)

export default AuthorTemplate
