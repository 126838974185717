import React from 'react'
import type { GatsbyBrowser } from 'gatsby'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

// Style
import './src/styles/index.scss'

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
}) => (
  <GoogleReCaptchaProvider reCaptchaKey={process.env.RECAPTCHA_KEY as string}>
    {element}
  </GoogleReCaptchaProvider>
)
