import React, { useState } from 'react'
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'

// Utils
import { shuffleArray } from '../../../utils/utils'

// Model
import { ComicModel, GenreModel } from '../../../models'

// Components
import ComicCard from '../../../components/atoms/ComicCard'

// Style
import { homeCarrousel, homeCarrouselWrapper } from './style.module.scss'

interface Props {
  comics: ComicModel[]
  genre: string
}

const Carrousel: React.FC<Props> = ({ comics, genre }) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const responsive = {
    0: { items: 2 },
    399: { items: 3 },
    1023: { items: 4 },
    1439: { items: 6 },
  }

  const renderSlides = () =>
    comics.map((c) => (
      <div key={`${genre}_${c.name}`}>
        <ComicCard comic={c} />
      </div>
    ))

  return (
    <div className={homeCarrouselWrapper}>
      <AliceCarousel
        items={renderSlides()}
        activeIndex={activeIndex}
        responsive={responsive}
        disableButtonsControls
      />
    </div>
  )
}

export default Carrousel
