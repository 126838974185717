// extracted by mini-css-extract-plugin
export var comicAuthor = "style-module--comic-author--Wx2AN";
export var comicBottom = "style-module--comic-bottom--qZc6l";
export var comicButton = "style-module--comic-button--GmfcL";
export var comicButtons = "style-module--comic-buttons--S4+hW";
export var comicCity = "style-module--comic-city--UIM68";
export var comicCover = "style-module--comic-cover--WXLUk";
export var comicCoverWrapper = "style-module--comic-cover-wrapper--fi2BU";
export var comicDetail = "style-module--comic-detail--yKrz9";
export var comicEmail = "style-module--comic-email--o21W1";
export var comicExtra = "style-module--comic-extra--GqdWR";
export var comicGenre = "style-module--comic-genre--AHsxI";
export var comicGenreLink = "style-module--comic-genre-link--9O1sU";
export var comicHalf = "style-module--comic-half--JwvYl";
export var comicInfo = "style-module--comic-info--FS-k-";
export var comicLink = "style-module--comic-link--cwm9g";
export var comicMature = "style-module--comic-mature--IZMmr";
export var comicStatus = "style-module--comic-status--31uug";
export var comicStatusContainer = "style-module--comic-status-container--PUmZ9";
export var comicTeam = "style-module--comic-team--LfVsM";
export var comicTeamTitle = "style-module--comic-team-title---0Knk";
export var comicTech = "style-module--comic-tech--rbe9Z";
export var comicTechTitle = "style-module--comic-tech-title--S57nQ";
export var comicTitle = "style-module--comic-title--F+ee5";
export var comicTop = "style-module--comic-top--hPtv4";